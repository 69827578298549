<template>
  <!-- 横屏 -->
  <horizontalScreen class="v-card-index"> 
    <!-- <scaleWrap> -->
      <div class="home">
        <div class="word-container">
            <span :class="{'active': item.active}" class="word" @click="wordClickHandler($event, item, index)" v-for="(item, index) in showWordList" :key="index">{{index + 1}}</span>
        </div>
        <img class="logo" src="@/assets/index/img-logo.png"/>
        <img class="game-logo" src="@/assets/card/img-sk.png"/>
        <img @click="backHandler" class="btn-back" src="@/assets/index/btn-back.png"/>
        <click-audio ref="clickAudio"></click-audio>
        <div class="btn-book">{{albumName}}</div>
        <img class="btn-title" src="@/assets/listenText/btn-title.png"/>
        <div class="btn-bottom-box">
          <div class="speed-box">
            <div class="icon-speed" :class="{'active': i === speed}" v-for="i in 5" :key="i" @click="speed = i">{{i}}</div>
          </div>
          <div class="type-box">
            <div class="font-color" :class="{'font-active': type===1}" @click="type=1">彩图</div>
            <div class="font-color" :class="{'font-active': type===0}" @click="type=0">黑字</div>
          </div>
          <img @click="confirmHandler" class="btn-confirm" src="@/assets/card/btn-confirm.png"/>
        </div>
      </div>
    <!-- </scaleWrap> -->
  </horizontalScreen>
</template>
<script>
import anime from 'animejs/lib/anime.es.js'
import horizontalScreen from '@/components/horizontal-screen'
import {isEmpty} from 'lodash'
import ClickAudio from '@/components/clickAudio.vue'
import {mapActions, mapMutations, mapGetters} from 'vuex'
export default {
  name: 'CardIndex',
  components: {
    horizontalScreen, ClickAudio
  },
  computed: {
    ...mapGetters(['albumName', 'albumId'])
  },
  data() {
    return {
      type: 1, // 1彩图 0黑字
      speed: 1, // 速度
      showWordList: []
    }
  },
  created() {
    this.SET_CARD_GRADE_IDS([])
    this.fetchList()
  },
  methods: {
    isEmpty,
    ...mapMutations(['SET_CARD_GRADE_IDS']),
    ...mapActions(['getFlashList']),
    async fetchList() {
      this.$loading.show('数据加载中。。。')
      try {
        const res = await this.getFlashList({
          albumId: this.albumId,
          current: 1,
          pageSize: 20
        })
        console.log('getFlashList', res)
        this.showWordList = res.result
      } finally {
        this.$loading.hide()
      }
    },
    async backHandler() {
      await this.$refs.clickAudio.playAudio()
      this.$router.push({name: 'Book'})
    },
    async confirmHandler () {
      await this.$refs.clickAudio.playAudio()
      const list = this.showWordList.filter(item => item.active)
      const len = list.length
      if(len < 3) {
        console.log('this', this.prototype)
        this.$myToast({msg: '请至少选择三课'});
      } else {
        const gradeIds = list.map(({gradeId}) => gradeId)
        this.SET_CARD_GRADE_IDS(gradeIds)
        this.$router.push({name: 'CardState', query: {speed: this.speed, type: this.type}})
      }
    },
    // 字点击
    async wordClickHandler(event, word, index) {
      const parentNode = event.target
      anime({
        targets: parentNode,
        scale: [
          { value: 1, duration: 100 },
          { value: 1.1, duration: 100 },
          { value: 1, duration: 100 }
        ],
        complete: () => {
          parentNode.style.transform = 'none'
        }
      });
      this.$set(this.showWordList[index], 'active', !this.showWordList[index].active)
      this.$refs.clickAudio.playAudio()
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card-index {
  // // font-family: 'Alibaba-PuHuiTi-Bold';
  // font-family: 'FZHuPo-M04S';
audio {
  position: absolute;
  top: -1000px;
  left: -1000px;
  z-index: -1;
}
.home {
  height: 100%;
  width: 100%;
  background: url(~@/assets/index/bg-index.png) center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  overflow: hidden;
}
// .logo {
//   width: 12px;
//   left: 5px;
//   top: 10PX;
//   position: fixed;
// }
.game-logo {
  width: 53px;
  height: 119px;
  left: 48px;
  top: 34px;
  position: fixed;
}
.btn-back {
  position: fixed;
  width: 65.5px;
  right: 10PX;
  top: 5PX;
}
.btn-book {
  color: #6eabf3;
  font-size: 22px;
  z-index: 9;
  position: fixed;
  width: 149px;
  height: 44.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('~@/assets/listenText/bg-book.png') center center no-repeat;   
  background-size: 100%;
  top: 30px;
  right: 100px;
}
.btn-title {
  z-index: 9;
  position: fixed;
  width: 161.6px;
  height: 49.5px;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}
.btn-bottom-box {
  z-index: 9;
  position: fixed;
  bottom: 15px;
  left: 15px;
  right: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  .speed-box {
    font-weight: bolder;
    width: 316.5px;
    height: 72.5px;
    background: url('~@/assets/card/bg-speed.png') center center no-repeat;   
    background-size: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 64px;
    box-sizing: border-box;
    .icon-speed {
      font-size: 30px;
      width: 42px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: url('~@/assets/card/icon-default.png') center center no-repeat;   
      background-size: 100%;
      color: #16453a;
      &.active {
        background: url('~@/assets/card/icon-active.png') center center no-repeat;   
        background-size: 100%;
        color: #fcfd2e;
      }
      & + .icon-speed {
        margin-left: 5px;
      }
    }
  }
  .type-box {
    font-weight: bolder;
    width: 188px;
    height: 72.5px;
    background: url('~@/assets/card/bg-btn.png') center center no-repeat;   
    background-size: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .font-color {
      width: 79px;
      height: 47px;
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      font-size: 28px;
      background-color: #a2fcb8;
      color: #22483c;
      border-radius: 18px;
      border: solid 4px #fff;
      & + .font-color {
        margin-left: 5px;
      }
    }
    .font-active {
      background-color: #d30100;
      color: #efef2b;
    }
  }
  .btn-confirm {
    width: 138.5px;
    height: 54px;
  }
}
.word-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  width: 70%;
  margin: 70px auto 0;
  font-weight: bolder;
  .word {
    margin: 10px 0 0 10px;
    font-size: 40px;
    color: #821115;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: url('~@/assets/listenText/btn-class.png') center center no-repeat;   
    background-size: 100%;
    &.active {
      background: #28cb18;
      border-radius: 16px;
    }
  }
}

}
</style>
