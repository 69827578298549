<template>
  <!-- 横屏 -->
  <horizontalScreen class="v-listenWord-course">
    <!-- <scaleWrap> -->
    <div class="home" ref="home">
      <img class="logo" src="@/assets/index/img-logo.png" />
      <img @click="backHandler" class="btn-back" src="@/assets/index/btn-back.png" />
      <img @click="nextStep" class="btn-play" src="@/assets/card/btn-ks.png" />
      <!-- <img class="game-logo" src="@/assets/index/img-ztrz-new.png"/> -->
      <div class="word-container">
        <!-- <img src="@/assets/card/1.jpeg" alt=""> -->
        <!-- <div class="title">{{showWord.title}}</div>
          <div class="detail">{{showWord.detail}}</div>
          <div class="hint">{{showWord.hint}}</div>
          <div class="item" v-for="(item, index) in showWord.list" :key="index">
            <span v-html="item.name"></span>
          </div> -->
      </div>
      <click-audio ref="clickAudio"></click-audio>
    </div>
    <!-- </scaleWrap> -->
  </horizontalScreen>
</template>
<script>
import anime from 'animejs/lib/anime.es.js'
import horizontalScreen from '@/components/horizontal-screen'
import { isEmpty } from 'lodash'
import ClickAudio from '@/components/clickAudio.vue'

export default {
  name: 'CardState',
  components: {
    horizontalScreen, ClickAudio
  },
  data () {
    return {
      showWord: {
        title: '闪卡说明',
        detail: '闪卡可以迅速开启右脑的强大记忆力，训练专注力',
        hint: '闪卡速度分五档',
        list: [
          { name: '第一档&nbsp;一秒钟闪一张，每闪10张暂停一次。' },
          { name: '第二档&nbsp;一秒钟闪二张，每闪20张暂停一次。' },
          { name: '第三档&nbsp;一秒钟闪三张，每闪30张暂停一次。' },
          { name: '第四档&nbsp;一秒钟闪四张，每闪40张暂停一次。' },
          { name: '第五档&nbsp;一秒钟闪五张，每闪50张暂停一次。' }
        ]
      }
    }
  },
  methods: {
    isEmpty,
    animeHandler (event) {
      const targetNode = event.target
      anime({
        targets: targetNode,
        scale: [
          { value: 1, duration: 100 },
          { value: 1.1, duration: 100 },
          { value: 1, duration: 100 }
        ],
        complete: () => {
          targetNode.style.transform = 'none'
        }
      });
    },
    async backHandler (event) {
      this.animeHandler(event)
      await this.$refs.clickAudio.playAudio()
      this.$router.back()
      window.wx.miniProgram.navigateBack()
      // this.$router.push({name: 'CardIndex', query: {speed: this.$route.query.speed, type: this.$route.query.type}})
    },
    async nextStep (event) {
      this.animeHandler(event)
      await this.$refs.clickAudio.playAudio()
      this.$router.replace({ name: 'CardCourse', query: { speed: this.$route.query.speed, type: this.$route.query.type } })
    }
  }
}
</script>

<style lang="scss" scoped>
.v-listenWord-course {

  // font-family: 'Alibaba-PuHuiTi-Bold';
  // // font-family: 'FZHuPo-M04S';
  .word-container {
    box-sizing: border-box;
    width: 70%;
    margin: 0 auto;
    height: 100%;
    background: #fffee2;
    background-image: url(~@/assets/card/1.jpeg);
    background-repeat: no-repeat;
    background-size: 100%;

    .title {
      justify-content: center;
      font-size: 36px;
      font-weight: bold;
      display: flex;
      height: 20%;
      align-items: center;
      color: #1d1718;
    }

    .detail {
      display: flex;
      height: 10%;
      align-items: center;
      font-size: 22px;
      color: #1d0d03;
      background: #fbc407;
      padding: 0 20px;
    }

    .hint {
      margin: 1% 0;
      padding-left: 40px;
      text-align: left;
      font-size: 23px;
      display: flex;
      height: 10%;
      align-items: center;
      color: #171106;
    }

    .item {
      font-size: 23px;
      padding-left: 40px;
      color: #171106;
      display: flex;
      height: 10%;
      align-items: center;

      .left {
        height: 100%;
        // padding: .7% 0;
        width: 130px;
        text-align: center;
        flex: 0 1 auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  audio {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: -1;
  }

  .home {
    height: 100%;
    width: 100%;
    background: url(~@/assets/listenWord/bg-play.png) center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  // .logo {
  //   width: 28px;
  //   left: 5px;
  //   top: 10PX;
  //   position: fixed;
  // }
  .btn-back {
    position: fixed;
    width: 65.5px;
    right: 10PX;
    top: 5PX;
  }

  .btn-play {
    position: fixed;
    width: 83.5px;
    height: 55.5px;
    right: 10px;
    bottom: 20px;
  }

}</style>
