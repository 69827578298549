<template>
  <!-- 横屏 -->
  <horizontalScreen class="v-card-course">
    <!-- <scaleWrap> -->
    <div class="home" ref="home">
      <img class="logo" src="@/assets/index/img-logo.png" />
      <img @click="backHandler" class="btn-back" src="@/assets/index/btn-back.png" />
      <!-- <img @click="startHandler" v-if="!working" class="btn-play" :src="`${btnKS}`"/> -->
      <img @click="continueHandler" v-show="showBtn" class="btn-play" :src="`${btnJX}`" />
      <!-- <img class="game-logo" src="@/assets/index/img-ztrz-new.png"/> -->
      <div class="word-container">
        <div ref="wordPlayBox" class="word-play-box">
          <img v-show="!working" class="img-kkn" src="@/assets/card/img-kkn.png" alt="">
          <p v-show="!working" class="title">刚才闪过的是什么？</p>
          <img v-show="!working" @click="checkHandler" class="btn-da" src="@/assets/card/btn-da.png" alt="">
          <div class="poster-box" v-show="working">
            <img v-for="(item, imgIndex) in currentList" v-show="index == imgIndex" :key="imgIndex" class="poster-img"
              :src="item" />
            <!--  -->
            <!-- <div v-for="(item, imgIndex) in currentList" v-show="index == imgIndex" :key="imgIndex" class="poster" :style="{backgroundImage: 'url(' + item + ')'}"></div> -->
            <!-- <div class="poster" :style="{backgroundImage: 'url(' + currentImg + ')'}"></div> -->
            <!-- <img class="poster" :src="currentImg" alt=""> -->
          </div>
        </div>
      </div>
      <click-audio ref="clickAudio"></click-audio>
    </div>
    <!-- </scaleWrap> -->
  </horizontalScreen>
</template>
<script>
import anime from 'animejs/lib/anime.es.js'
import horizontalScreen from '@/components/horizontal-screen'
import {isEmpty} from 'lodash'
import ClickAudio from '@/components/clickAudio.vue'
import {mapGetters, mapActions} from 'vuex'
export default {
  name: 'CardCourse',
  components: {
    horizontalScreen, ClickAudio
  },
  computed: {
    ...mapGetters(['cardGradeIds']),
    currentList() {
      if (this.type === 1) {
        // 1彩图
        return this.list.map(item => {
          return item.color.img
        })
      }
      return this.list.map(item => {
        return item.black.img
      })
    },
    currentImg() {
      return this.currentList[this.index]
    }
  },
  data() {
    return {
      showBtn: false, // 是否显示继续按钮
      speed: Number(this.$route.query.speed), // 1彩图 0黑字
      type: Number(this.$route.query.type), // 速度
      working: true,
      btnKS: require(`@/assets/card/btn-ks.png`),
      btnJX: require(`@/assets/card/btn-jx.png`),
      list: [],
      index: 0,
      timer: null,
      loadImgCount: 0
    }
  },
  created() {
    this.fetchList()
  },
  mounted() {
    this.showBtn = false
    this.working = true
  },
  destroyed() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    isEmpty,
    ...mapActions(['getFlash']),
    preloadImages() {
      return new Promise((resolve) => {
        let imgList = []
        if (this.type === 1) {
          // 1彩图
          imgList = this.list.map(item => {
            return item.color.img
          })
        } else {
          // 黑字
          imgList = this.list.map(item => {
            return item.black.img
          })
        }
        if (imgList.length) {
          for (let i = 0; i < imgList.length; i++) {
            let image = new Image()
            console.log('加载', imgList[i],)
            image.src = imgList[i],
              // 图片加载
            image.onload = () => {
              this.loadImgCount++
              console.log('loadImgCount', this.loadImgCount)
              // 计算图片加载的百分数，绑定到percent变量
              if(this.loadImgCount === imgList.length - 1) {
                // 加载完毕
                console.log('完毕', this.loadImgCount)
                resolve()
              }
            }
          }
        }
      })
    },
    async fetchList() {
      this.$loading.show('数据加载中。。。')
      try {
        const res = await this.getFlash({
          gradeId: this.cardGradeIds
        })
        this.list = res.result.map(item => {
          return {
            name: item.fontName,
            id: item.fontId,
            color: {
              img: item.colorUrl
            },
            black: {
              img: item.fontUrl
            }
          }
        })
        await this.preloadImages()
        this.timerHandler()
      } finally {
        this.$loading.hide()

      }
    },
    animeHandler(event) {
      const targetNode = event.target
      anime({
        targets: targetNode,
        scale: [
          { value: 1, duration: 100 },
          { value: 1.1, duration: 100 },
          { value: 1, duration: 100 }
        ],
        complete: () => {
          targetNode.style.transform = 'none'
        }
      });
    },
    async backHandler(event) {
      this.animeHandler(event)
      await this.$refs.clickAudio.playAudio()
      this.$router.back()
      window.wx.miniProgram.navigateBack()
      // this.$router.push({name: 'CardIndex', query: {speed: this.speed, type: this.type}})
    },
    startHandler(event) {
      this.animeHandler(event)
      this.$refs.clickAudio.playAudio()
      // this.working = !this.working
      this.timerHandler()
    },
    continueHandler(event) {
      this.animeHandler(event)
      this.$refs.clickAudio.playAudio()
      // this.working = !this.working
      this.showBtn = false
      this.working = true
      if (this.index > this.currentList.length-2) {
        // 已经走过一遍了
        this.index = 0
        this.showBtn = false
        this.working = true
        this.$router.push({name: 'CardState', query: {speed: this.speed, type: this.type}})
      } else {
        this.index++
        this.timerHandler()
      }
    },
    checkHandler(event) {
      this.animeHandler(event)
      this.$refs.clickAudio.playAudio()
      this.working = true
      console.log('index', this.index)
    },
    timerHandler() {
      // this.speed
      // 1s 1张 10张暂停一次
      // 1s 2张 20张暂停一次
      // 1s 3张 30张暂停一次
      // 1s 4张 40张暂停一次
      // 1s 5张 50张暂停一次
      let speedObj = {
        1: 10,
        2: 20,
        3: 30,
        4: 40,
        5: 50
      }
      const count = speedObj[this.speed]
      this.timer = setInterval(()=> {
        console.log('timer', this.index, count, this.index%count, this.currentList)
        if (this.index !== 0 && (this.index+1)%(count) === 0 || this.index > this.currentList.length - 2) {
          clearInterval(this.timer)
          this.timer = null
          this.working = false
          this.showBtn = true
        } else {
          this.index++
        }
      }, 1000/this.speed)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-card-course {

  // font-family: 'Alibaba-PuHuiTi-Bold';
  // font-family: 'FZHuPo-M04S';
  .word-container {
    display: flex;
    width: 70%;
    margin: 0 auto;
    height: 100%;
    position: relative;

    .word-play-box {
      width: 100%;
      height: auto;
      // height: 285.7px;
      // height: calc(100vw - 60px);
      background: url(~@/assets/word/bg-window.png) center center;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      overflow: hidden;
      flex: 0 1 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      flex-direction: column;

      // > img {
      //   margin-top: 6%;
      //   width: 70%;
      //   height: auto;
      // }
      .title {
        text-align: center;
        font-size: 42px;
        color: #000;
        margin: 5% 0 7% 0;
        font-weight: bolder;
      }

      .img-kkn {
        width: 256.5px;
        height: 85.5px;
        display: block;
      }

      .btn-da {
        width: 163px;
        height: 99.5px;
        display: block;
      }

      .poster-box {
        width: 80%;
        height: 80%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .poster-img {
        height: 100%;
      }

      .poster {
        width: 100%;
        height: 100%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  audio {
    position: absolute;
    top: -1000px;
    left: -1000px;
    z-index: -1;
  }

  .home {
    height: 100%;
    width: 100%;
    background: url(~@/assets/listenWord/bg-play.png) center center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  // .logo {
  //   width: 28px;
  //   left: 5px;
  //   top: 10PX;
  //   position: fixed;
  // }
  .btn-back {
    position: fixed;
    width: 65.5px;
    right: 10PX;
    top: 5PX;
  }

  .btn-play {
    position: fixed;
    width: 83.5px;
    height: 55.5px;
    right: 10px;
    bottom: 20px;
  }

}</style>
